;(function () {

  'use strict';

  /******************************************
   * Password Fields
   *
   * Add Show/Hide button to password fields
   ******************************************/
  const passwordFields = document.querySelectorAll('input[data-password-field]');

  if (passwordFields) {
    const togglePasswordField = function(field) {
      field.type = field.type === 'password' ? 'text' : 'password';
    };

    const togglePasswordFieldButtonText = function(button) {
      button.innerText = button.innerText === 'Show' ? 'Hide' : 'Show';
    };

    document.addEventListener('click', function(ev) {
      if (ev.target.matches('input[data-password-field] + button')) {
        togglePasswordFieldButtonText(ev.target);
        togglePasswordField(ev.target.parentNode.querySelector('input[data-password-field]'));
      }
    });
  }

  /******************************************
   * Lightswitch Fields
   *
   ******************************************/
  const lightswitches = document.querySelectorAll('[data-lightswitch]');

  if (lightswitches) {
    const toggleLightswitch = function(el) {
      let isOn = el.checked;

      const toggle = el.parentNode.querySelector('[data-toggle]');
      const on = el.parentNode.querySelector('[data-on]');
      const off = el.parentNode.querySelector('[data-off]');
      const track = el.parentNode.querySelector('[data-track]');

      if (isOn) {
        toggle.classList.add('border-smiley');
        on.classList.remove('hidden');
        off.classList.add('hidden');
        track.classList.add('justify-end','bg-smiley');
        track.classList.remove('justify-start');
      } else {
        toggle.classList.remove('border-smiley');
        on.classList.add('hidden');
        off.classList.remove('hidden');
        track.classList.remove('justify-end','bg-smiley');
        track.classList.add('justify-start');
      }
    };

    document.addEventListener('change', function(ev) {
      if (ev.target.matches('[data-lightswitch] input[type="checkbox"]')) {
        toggleLightswitch(ev.target);
      }
    });
  }

  /******************************************
   * Auto expanding Textareas
   *
   ******************************************/
  const autoExpand = function (field) {

    // Reset field height
    field.style.height = 'inherit';

    // Get the computed styles for the element
    let computed = window.getComputedStyle(field);

    // Calculate the height
    let height = parseInt(computed.getPropertyValue('border-top-width'), 10)
      // + parseInt(computed.getPropertyValue('padding-top'), 10)
      + field.scrollHeight
      // + parseInt(computed.getPropertyValue('padding-bottom'), 10)
      + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    field.style.height = height + 'px';

  };

  document.addEventListener('input', function (event) {
    if (event.target.tagName.toLowerCase() !== 'textarea') return;
    autoExpand(event.target);
  }, false);

  /******************************************
   * Redactor fields
   *
   ******************************************/
  const initRedactorFields = function(rootElementSelector = '') {
    $R(`${rootElementSelector} [data-redactor-field]`, {
      buttons:['html','format', 'bold', 'lists', 'link', 'horizontalrule'],
      buttonsAdd:['line'],
      formatting:['p', 'h2', 'h3', 'h4'],
      linkNewTab:true,
      toolbarFixed:false
    });
  };

  document.addEventListener('cfa.initRedactorFields', function(ev) {
    let rootElementId = ev.target.id;
    let redactorFields = document.querySelectorAll(`#${rootElementId} [data-redactor-field]`);

    if (rootElementId && redactorFields.length) {
      initRedactorFields(`#${rootElementId}`);
    }
  });

  /******************************************
   * File fields
   *
   ******************************************/
  const deleteFile = function(fileDeleteButton, fileId, baseInputName) {
    let data = {};
    let csrfTokenName = document.querySelector('body').dataset.csrfTokenName;
    let csrfTokenValue = document.querySelector('body').dataset.csrfTokenValue;
    data[csrfTokenName] = csrfTokenValue;

    data.assetId = fileId;

    fetch('/actions/assets/delete-asset', {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(function(result) {
        if (result.message === 'Asset deleted.') {
          let selectedAssetImage = fileDeleteButton.closest('label').querySelector('[data-selected-asset-image]');
          selectedAssetImage.src = '';
          selectedAssetImage.classList.add('hidden');
          let selectedAssetTitle = fileDeleteButton.closest('label').querySelector('[data-selected-asset-title]');
          selectedAssetTitle.innerText = '';
          fileDeleteButton.classList.add('hidden');
          let assetUpload = fileDeleteButton.closest('label').querySelector('[data-asset-upload]');
          assetUpload.classList.remove('hidden');
        }
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.status); // xhr.status
        console.log(error.statusText); // xhr.statusText
        return false;
      });
  };

  const removeFile = function(fileRemoveButton, fileId, baseInputName) {
    let existingFiles = fileRemoveButton.closest('label').querySelector('[data-existing-files]');
    let hiddenInput = fileRemoveButton.closest('label').querySelector('[data-asset-id]');
    hiddenInput.value = '';
    let selectedAssetImage = fileRemoveButton.closest('label').querySelector('[data-selected-asset-image]');
    selectedAssetImage.src = '';
    selectedAssetImage.classList.add('hidden');
    let selectedAssetTitle = fileRemoveButton.closest('label').querySelector('[data-selected-asset-title]');
    selectedAssetTitle.innerText = '';
    fileRemoveButton.classList.add('hidden');
    let assetUpload = fileRemoveButton.closest('label').querySelector('[data-asset-upload]');
    assetUpload.classList.remove('hidden');

    if (existingFiles) {
      existingFiles.classList.remove('hidden');
    }
  };

  const addExistingFile = function(selectedAssetButton) {
    let selectedAssetId = selectedAssetButton.dataset.id;
    let selectedAssetImgUrl = selectedAssetButton.querySelector('img').src;
    let selectedImageTitle = selectedAssetButton.querySelector('[data-asset-title]').innerText;
    // Set selected image
    let selectedAssetImage = selectedAssetButton.closest('label').querySelector('[data-selected-asset-image]');
    selectedAssetImage.src = selectedAssetImgUrl;
    selectedAssetImage.classList.remove('hidden');
    // Set selected image title
    let selectedAssetTitle = selectedAssetButton.closest('label').querySelector('[data-selected-asset-title]');
    selectedAssetTitle.innerText = selectedImageTitle;
    // Set selected image hidden input
    let hiddenInput = selectedAssetButton.closest('label').querySelector('[data-asset-id]');
    hiddenInput.value = selectedAssetId;
    // Hide existing files
    let existingFiles = selectedAssetButton.closest('label').querySelector('[data-existing-files]');
    existingFiles.classList.add('hidden');
    // Show remove file button
    let fileRemoveButton = selectedAssetButton.closest('label').querySelector('[data-remove-file]');
    fileRemoveButton.dataset.id = selectedAssetId;
    fileRemoveButton.classList.remove('hidden');
    // Hide file upload button
    let assetUpload = selectedAssetButton.closest('label').querySelector('[data-asset-upload]');
    assetUpload.classList.add('hidden');
    // Update status message container
    let statusMessageContainer = selectedAssetButton.closest('label').querySelector('[data-file-notifications]');
    statusMessageContainer.dataset.fileNotifications = selectedAssetId;
  };

  const addFileEventListeners = function(rootElement) {
    rootElement.addEventListener('click', function(ev) {
      if (ev.target.closest('[data-delete-file]') && !ev.target.closest('[data-delete-file]').classList.contains('hidden')) {
        ev.stopPropagation();
        ev.preventDefault();
        let confirmDeleteFile = confirm('Are you sure you want to delete this file? (You cannot undo this action.)');

        if (confirmDeleteFile) {
          let fileDeleteButton = ev.target.closest('[data-delete-file]');
          let fileId = fileDeleteButton.dataset.id;
          let baseInputName = fileDeleteButton.closest('label[data-name]').dataset.name;

          deleteFile(fileDeleteButton, fileId, baseInputName);
        }
      }
      if (ev.target.closest('[data-remove-file]') && !ev.target.closest('[data-remove-file]').classList.contains('hidden')) {
        ev.stopPropagation();
        ev.preventDefault();
        let confirmRemoveFile = confirm('Are you sure you want to remove this file? (You cannot undo this action.)');

        if (confirmRemoveFile) {
          let fileRemoveButton = ev.target.closest('[data-remove-file]');
          let fileId = fileRemoveButton.dataset.id;
          let baseInputName = fileRemoveButton.closest('label[data-name]').dataset.name;

          removeFile(fileRemoveButton, fileId, baseInputName);
        }
      }
      if (ev.target.closest('[data-existing-files] [data-id]')) {
        // Move selected asset into chosen area, set id in hidden form field to submit and hide existing files - lots to do here!
        let selectedAssetButton = ev.target.closest('[data-existing-files] [data-id]');
        addExistingFile(selectedAssetButton);
        ev.stopPropagation();
      }
    });
  };

  /******************************************
   * Initialise all form fields
   *
   ******************************************/
  const initFormFields = function(form) {
    // initialise redactor fields for the entry
    form.dispatchEvent(
      new Event('cfa.initRedactorFields', {
        "bubbles":true,
        "cancelable":true
      })
    );
    addFileEventListeners(form);
  };

  document.addEventListener('cfa.initFormFields', function(ev) {
    let form = ev.target.closest('form');
    initFormFields(form);
  });

  let forms = document.querySelectorAll('[data-element-form]');

  Array.prototype.forEach.call(forms, function(form) {
    initFormFields(form);
  });
})();
